<template>
  <v-card
    class="mx-auto"
    max-width="374"
    elevation="0"
  >
    <v-card-text class="title black--text text-center">О программе</v-card-text>
    <v-card-text class="text-center">
      SPA "Subscribers"
      <br>Версия: 17072024
      <br>
      <a href="/agreement.pdf">Пользовательское соглашение</a>
      <v-card-text>
        Связаться с разработчиком:
      </v-card-text>
      e-mail: <a href="mailto:iaryndin@yandex.ru">iaryndin@yandex.ru</a>
      <br>
      телефон: +79630255151 <a href="tel:+79630255151">позвонить</a>

    </v-card-text>

  </v-card>
</template>

<script>
  export default {
    name: "About",
    data: () => ({
      categories: [],
      services: [],
      select: "",
    }),

    methods: {
      async loadCategoryList() {
        const response = await fetch(`${this.$store.state.server}/category/load-category-list-for-client`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({owner: this.$store.getters.ownerId})
        })

        const json = await response.json()
        if (json.message) {
          this.$store.commit("setServerMessage", json.message)
        }
        if (response.status === 200) {
          this.categories = json.categories
        }
      },

      async loadServiceList() {
        const response = await fetch(`${this.$store.state.server}/service/load-service-list-for-client`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({owner: this.$store.getters.ownerId})
        })

        const json = await response.json()
        if (json.message) {
          this.$store.commit("setServerMessage", json.message)
        }
        this.services = json.services
      },

      getServicesOfCategory(category) {
        if (category) {
          return this.services.filter(s => s.category === category._id)
        }
        return []
      }
    },

    async mounted() {
      await this.loadServiceList()
      await this.loadCategoryList()
      if (this.$route.query.id) {
        this.select = this.categories.find(c => c._id === this.$route.query.id).title
      } else {
        this.select = this.categories[0].title
      }
    },

    watch: {
      item: function (i) {
        if (i !== undefined) {
          this.service = this.getServicesOfCategory[i]
          this.title = this.getServicesOfCategory[i].title
          this.price = this.getServicesOfCategory[i].price
        } else {
          this.service = ""
          this.title = ""
          this.price = ""
        }
      }
    }
  }
</script>

<style scoped>

</style>
